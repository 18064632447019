.future{
    
    display: flex;
    justify-content: center;
    align-items: center;
    
    overflow: hidden;
    text-align: left;
    padding-top: 7em;

    
    @media (max-width: $screen-sm) {
        padding-top: 0em;
    }


    
        
    
 

    > :nth-child(1) {
        width: 1420px;
        padding-left: 4vw;
        padding-right: 4vw;
        display: grid;
        grid-template-columns: 1fr;
        grid-template-columns: 1fr 1fr;

       

        @media (max-width: $screen-sm) {
            grid-template-columns: 1fr;
        }
        
        
    }


   
}

.future-var{
    padding: 1em;
    position: relative;

    @media (max-width: $screen-md) {
        width: 30em;
        
    }

    @media (max-width: $screen-sm) {
        width: 100%;
        
    }
   

    h1 {
        color: $brand-secondary;
        font-family: poppins-bold;
        font-size: 50px;
        margin: 0;
        text-transform: uppercase;

        @media (max-width: $screen-phone) {
            font-size: 10vmin;
            
        }
    }

    h2 {
        color: $brand-primary;
        font-family: poppins-regular;
        font-size: 23px;
        margin: 0;
        text-transform: uppercase;
        @media (max-width: $screen-phone) {
            font-size: 5vmin;
            
        }
    }

    p{
        color: $brand-primary;
        font-family: poppins-regular;

        

        @media (max-width: $screen-phone) {
            font-size: 3.5vmin;
            
        }
    }

    hr {
        width: 4em;
        margin-bottom: 2em;
        margin-top: 0.8em;
        border: 2px solid $brand-secondary;
        border-radius: 5px;
        background: $brand-secondary;
        margin-left: 0;
            margin-right: 0;

        

    }

    ul{
     
        padding-left: 1em;
        list-style: initial;
       

      

        @media (max-width: $screen-sm) {
            padding-bottom: 2em;
           
        }

        

        
        li{
          color: $brand-primary;
          line-height: 2em;
          font-family: poppins-regular;
    
          @media (max-width: $screen-phone) {
            font-size: 14px;
            
        }
        }
    
        li::marker{
          color: $brand-secondary
        }
      }

}

.future-var2{
    padding: 1em;
    position: relative;
    order: 2;

    @media (max-width: $screen-md) {
        width: 30em;
        
    }

    

    @media (max-width: $screen-sm) {
        width: 100%;
        
    }
   

   

    h1 {
        color: $brand-secondary;
        font-family: poppins-bold;
        font-size: 50px;
        margin: 0;
        text-transform: uppercase;

        @media (max-width: $screen-phone) {
            font-size: 10vmin;
            
        }
    }

    h2 {
        color: $brand-primary;
        font-family: poppins-regular;
        font-size: 23px;
        margin: 0;
        text-transform: uppercase;
        @media (max-width: $screen-phone) {
            font-size: 5vmin;
            
        }
    }

    p{
        color: $brand-primary;
        font-family: poppins-regular;

        @media (max-width: $screen-sm) {
            padding-top: 2em;
            text-align: center;
            padding-bottom: 2em;
        }

        @media (max-width: $screen-phone) {
            font-size: 14px;
            
        }
    }

    hr {
        width: 4em;
        margin-bottom: 2em;
        margin-top: 0.8em;
        border: 2px solid $brand-secondary;
        border-radius: 5px;
        background: $brand-secondary;
        margin-left: 0;
            margin-right: 0;

        

    }

    ul{
     
        padding-left: 1em;

        list-style: initial;
        
        @media (max-width: $screen-sm) {
            padding-top: 2em;
            padding-bottom: 2em;
        }

        li{
          color: $brand-primary;
          line-height: 2em;
    
          @media (max-width: $screen-phone) {
            font-size: 14px;
            
        }
        }
    
        li::marker{
          color: $brand-secondary
        }
      }

    
}

.future2{
    
    display: none;
    justify-content: center;
    align-items: center;
    text-align: center;
    overflow: hidden;
    text-align: left;
        display: flex;
        padding-top: 3em;

    


   

    @media (max-width: $screen-sm) {
        text-align: left;
        display: flex;
        padding-top: 0em;
    }
 

    > :nth-child(1) {
    
        padding-left: 4vw;
        padding-right: 4vw;
        display: grid;
        grid-template-columns: 1fr;
        grid-template-columns: 1fr 1fr;
            width: 1420px;

       

        @media (max-width: $screen-sm) {
            grid-template-columns: 1fr;
        }
    
    }
 
}



.phone-container2{
    
   
        display: block;
    
}




.card-img2{

    
    

    @media (max-width: $screen-lg) {

        display: flex;
        justify-content: center;
        align-items: center;
        height: auto;
    }

    @media (max-width: $screen-sm) {

      order: 2;
    }
    
    img{
        width: 30em;
        @media (max-width: $screen-sm) {
            width: 25em;
        }
      

    }
}






.phone-img2{
    
    display: flex;
        justify-content: center;
        align-items: center;
        height: auto;

    
    img{
        width: 25em;
        @media (max-width: $screen-sm) {
            width: 20em;
        }

    }
}

