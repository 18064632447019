.roadmap{
  color: $brand-primary;
  font-family: poppins-regular;

    width: 100%;
    
    

    > :nth-child(1) {
        max-width: 1420px;
        padding-left: 4vw;
        padding-right: 4vw;
        margin-left: auto;
        margin-right: auto;
    }

    .roadmap-header{
      text-align: center;
        padding: 1em;
    
        h1 {
          color: $brand-secondary;
          font-family: poppins-bold;
          font-size: 50px;
          margin: 0;
          text-transform: uppercase;
  
          @media (max-width: $screen-phone) {
              font-size: 10vmin;
              
          }
      }
  
      h2 {
          color: $brand-primary;
          font-family: poppins-regular;
          font-size: 23px;
          margin: 0;
          text-transform: uppercase;
          @media (max-width: $screen-phone) {
              font-size: 5vmin;
              
          }
      }
  
      p{
          color: $brand-primary;
          font-family: poppins-regular;
  
          @media (max-width: $screen-phone) {
              font-size: 3.5vmin;
              
          }
      }
    
        hr {
            width: 4em;
            margin-bottom: 2em;
            margin-top: 0.8em;
            margin-left: auto;
            margin-right: auto;
            border: 2px solid $brand-secondary;
            border-radius: 5px;
            background: $brand-secondary;
            
            
        }   
    
        

        
    }

    

    
   
}

.timeline-content-desktop{

.timeline-data{
  display: grid;
  grid-template-columns: 1fr max-content 1fr;
  column-gap: 1.5rem;
  height: 20em;

  

  p{
    font-family: poppins-bold;
    color: $brand-primary;
    font-size: 21px;

    
  }

  
  .timeline-var{
    background: rgba(255, 255, 255, 0.375);
    box-shadow: $box-shadow;
    border-radius: 1rem;
    border: 1px solid rgba(255, 255, 255, 0.125);
    padding: 1em;
    padding-left: 2em;
    margin-bottom: 4em;
  }
  
  ul{
    list-style: initial;
    
    li{
      color: $brand-primary;
      line-height: 2em;

      @media (max-width: $screen-phone) {
        font-size: 14px;
        
    }
    }

    li::marker{
      color: $brand-secondary
    }
  }
}



.timeline-rounder{
  display: inline-block;
  width: 2em;
  height: 2em;
  background-color: $brand-secondary;
  border-radius: 50%;
  background-image: url(../../../images/tick.png);
  background-repeat:no-repeat;
  background-position: center center;
  background-size: 0.8em;
      
}

.timeline-line{
  display: block;
  width: 0.2em;
  height: 19em;
  background: $brand-secondary;
  transform: translate(14px, -7px);
  
}

@media (max-width: $screen-sm) {
  display: none;
  
}

}

.timeline-content-mobile{
  display: none;
  .timeline-data{
    display: grid;
    grid-template-columns: 1fr max-content 1fr;
    column-gap: 1.5rem;
    height: 20em;
    

    @media (max-width: $screen-sm) {
      grid-template-columns: max-content 1fr;
      
    }
  
    p{
      font-family: poppins-bold;
      color: white;
      font-size: 16px;
      background: $brand-secondary;
      display: inline-block;
      position: absolute;
      bottom: 1em;
      right: 1em;
      padding: 0.2em;

      @media (max-width: $screen-phone) {
        font-size: 14px;
      }
    }
  
    
    .timeline-var{
      background: rgba(255, 255, 255, 0.375);
      box-shadow: $box-shadow;
      border-radius: 1rem;
      border: 1px solid rgba(255, 255, 255, 0.125);
      padding: 1em;
      padding-left: 2em;
      margin-bottom: 4em;
      position: relative;
    }
    
    ul{
      list-style: initial;
      @media (max-width: $screen-phone) {
        padding-bottom: 2em;
      }
      
      li{
        color: $brand-primary;
        line-height: 2em;
  
        @media (max-width: $screen-phone) {
          font-size: 14px;
          
      }
      }
  
      li::marker{
        color: $brand-secondary
      }
    }
  }
  
  
  
  .timeline-rounder{
    display: inline-block;
    width: 2em;
    height: 2em;
    background-color: $brand-secondary;
    border-radius: 50%;
    background-image: url(../../../images/tick.png);
    background-repeat:no-repeat;
    background-position: center center;
    background-size: 0.8em;
        
  }
  
  .timeline-line{
    display: block;
    width: 0.2em;
    height: 19em;
    background: $brand-secondary;
    transform: translate(14px, -7px);
    
  }
  
  @media (max-width: $screen-sm) {
    display: block;
    
  }
  
  }


  .form-wrapper{
    padding-top: 5em;
    width: 40em;
    margin-left: auto;
    margin-right: auto;

    @media (max-width: $screen-sm) {
      width: 90%;
    }

    p{
      text-align: center;
      color: $brand-primary;
      font-family: poppins-Bold;
      padding-bottom: 1em;
    }

    form{
      border-bottom: 2px solid $brand-secondary;
      display:flex;
      flex-direction:row;
      width: 40em;
      margin-left: auto;
      margin-right: auto;
      background: transparent;
  
      @media (max-width: $screen-sm) {
        width: 90%;
      }

      
  
      input{
        flex-grow:2;
        border:none;
        height: 3em;
        background: transparent;
        
  
        &:focus{
          outline: none;
        }
      }
  
      button{
        border: none;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: transparent;
        color: $brand-primary;
      }
  
      
    }
  }

