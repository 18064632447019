.howtobuy{
    

    width: 100%;
    text-align: center;
    

    > :nth-child(1) {
        max-width: 1420px;
        padding-left: 4vw;
        padding-right: 4vw;
        margin-left: auto;
        margin-right: auto;
    }

    .howtobuy-header{
        padding: 1em;
        
        h1 {
          color: $brand-secondary;
          font-family: poppins-bold;
          font-size: 50px;
          margin: 0;
          text-transform: uppercase;
  
          @media (max-width: $screen-phone) {
              font-size: 10vmin;
              
          }
      }
  
      h2 {
          color: $brand-primary;
          font-family: poppins-regular;
          font-size: 23px;
          margin: 0;
          text-transform: uppercase;
          @media (max-width: $screen-phone) {
              font-size: 5vmin;
              
          }
      }
  
      p{
          color: $brand-primary;
          font-family: poppins-regular;
  
          @media (max-width: $screen-phone) {
              font-size: 3.5vmin;
              
          }
      }
    
        hr {
            width: 4em;
            margin-bottom: 2em;
            margin-top: 0.8em;
            margin-left: auto;
            margin-right: auto;
            border: 2px solid $brand-secondary;
            border-radius: 5px;
            background: $brand-secondary;
            
            
        }   

        .option{
            display: flex;
            justify-content: center;

        
        h3{
            color: $brand-primary;
            font-family: poppins-regular;
            font-size: 23px;
            padding: 1em;
            text-transform: uppercase;
            
        }
        }
    }
   
}

.video-container{
    max-width: 1420px;
        padding-left: 4vw;
        padding-right: 4vw;
        margin-left: auto;
        margin-right: auto;
    h1{
    color: orange;
    }

    
    

}

.slide-container{
    cursor: e-resize;
        img{
            width: 90%;
            margin-bottom: 5em;
        }

        
}


.player-wrapper {
    position: relative;
    padding-top: 45%;
    @media (max-width: $screen-sm) {
        padding-top: 90%;
      }
  }
  
  .react-player {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
   
  }