nav ul {
  margin: 0;
  text-align: center;

  li {
    font-family: poppins-regular;
    font-size: 16px;
    cursor: pointer;
    display: inline-block;
    position: relative;
    padding: 12px;
    text-transform: uppercase;
    margin: 0;

    &.first {
      padding-left: 0;
    }

    &.last {
      padding-right: 0;
    }

    a {
      color: $brand-primary
    }

  }
}

.buynow-container {
  border: 2px solid $brand-primary;
  border-radius: 1em;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.7em 2em;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.375);
    box-shadow: $box-shadow;
    border-radius: 2rem;
    border: 1px solid rgba(255, 255, 255, 0.125);

    

  p {
    color: $brand-primary;
    font-family: poppins-bold;
    text-transform: uppercase;

    

  }

  @media (max-width: $screen-phone) {
      
    display: none !important;
    
}

}

.nav-desktop {
  display: flex;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;


  @media (max-width: $screen-md) {
    display: none;
  }
}

.nav-mobile {
  display: none;
  padding-right: 1vw;
  -webkit-animation-name: fadeIn;
  -webkit-animation-duration: 0.5s;
  animation-name: fadeIn;
  animation-duration: 0.5s;

  @media (max-width: $screen-md) {
    display: block;
  }

  .fa-bars {
    font-size: 2em;
  }
}

.Nav {
  z-index: 101;
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 380px;
  background-color: white;
  display: flex;
  flex-direction: column;
  transform: translateX(-100%);
  transition: transform 0.15s ease-in-out;
  background: rgba(255, 255, 255, 1);
  box-shadow: $box-shadow;
  border-top-right-radius: 2rem;
  border-bottom-right-radius: 2rem;
  border: 1px solid rgba(255, 255, 255, 0.125);
  padding: 2em 1em;

  @media (max-width: $screen-phone) {
    width: 100%;
  }
}


.Nav2 {
  z-index: 100;
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  background-color: transparent;
  opacity: 1;
  display: flex;
  flex-direction: column;
  transform: translateX(-100%);

}

.Nav--open {
  transform: translateX(0);
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }

  20% {
    opacity: 0;
  }

  40% {
    opacity: 0.3;
  }

  60% {
    opacity: 0.5;
  }

  80% {
    opacity: 0.9;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  20% {
    opacity: 0;
  }

  40% {
    opacity: 0.3;
  }

  60% {
    opacity: 0.5;
  }

  80% {
    opacity: 0.9;
  }

  100% {
    opacity: 1;
  }
}

.nav-header{
  width: 100%;
  justify-content: space-between;
  display: flex;
  


  
  .fa-times{
    color: $brand-primary;
    font-size: 1.5em;
    cursor: pointer;
  }

}

.mobile-nav ul {
  margin: 0;
  text-align: left;
  padding-top: 2em;
  

  li {
    font-family: poppins-regular;
    font-size: 16px;
    cursor: pointer;
    display: block;
    position: relative;
    padding: $grid-gutter-width/4;
    text-transform: uppercase;
    margin: 0;
    
  

    &.first {
      padding-left: 0;
    }

    &.last {
      padding-right: 0;
    }

    a {
      color: $brand-primary
    }

  }
}

.nav-buynow-container {
 
  border: 2px solid $brand-primary;
  border-radius: 1em;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.7em 2em;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.375);
    box-shadow: $box-shadow;
    border-radius: 2rem;
    border: 1px solid rgba(255, 255, 255, 0.125);
    display: none;
    margin-top: 1em;

    

  p {
    color: $brand-primary;
    font-family: poppins-bold;
    text-transform: uppercase;

    

  }

  @media (max-width: $screen-phone) {
      
    display: flex;
    
}

}