.header {
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  right: 0;
  
  max-width: 100%;
  margin-right: auto;
  margin-left: auto;
  background-color: $body-bg;

  .header-wrapper{
    display: grid;
    grid-template-columns: 20% 60% 20%;
    max-width: 1420px;
    margin-right: auto;
    margin-left: auto;
    height: 5em;
    
    @media (max-width: $screen-md) {
      grid-template-columns: 1fr 1fr;
    }
  }

 

  .header-div-1 {
    display: flex;
    align-items: center;
    padding-left: 4vw;
    justify-content: flex-start;
  }

  .header-div-2 {
    display: flex;
    align-items: center;
    justify-content: center;

    @media (max-width: $screen-md) {
      display: none;
    }
  }

  .header-div-3 {
    display: flex;
    align-items: center;
    padding-right: 4vw;
    justify-content: flex-end;
  }

  img {
    width: 10em
  }
}

.Marquee {
  width: 100%; /* the plugin works for responsive layouts so width is not necessary */
  overflow: hidden;
  
  background: #87F6AD; 
  color: white;


  p{
    font-size: 18px;
    font-family: poppins-Bold;
    margin: 0;
    padding-top: 0.4em;
    padding-bottom: 0.4em;
    
  }

  .whitespace{
    padding-left: 1em;
    padding-right: 1em;
  }
  
}

