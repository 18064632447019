.section1{
    height: 100%;
    min-height: 100%;
    padding-bottom: 11em;
    

    @media (max-width: $screen-sm) {
        
        padding-bottom: 4em;
      }
}

.section2{
    height: 100%;
    min-height: 100%;
    padding: 10em 0em;

    

    @media (max-width: $screen-sm) {
        
        padding: 4em 0em;
      }
}

.section3{
    height: 100%;
    min-height: 100%;
    padding: 10em 0em;

    @media (max-width: $screen-sm) {
        
        padding: 4em 0em;
      }
}

.section4{
    height: 100%;
    min-height: 100%;
    padding: 10em 0em;

    @media (max-width: $screen-sm) {
        
        padding: 4em 0em;
      }
}

.section5{
    height: 100%;
    min-height: 100%;
    padding: 10em 0em;

    @media (max-width: $screen-sm) {
        
        padding: 4em 0em;
      }
}

.section6{
    height: 100%;
    min-height: 100%;
    padding-bottom: 10em;

    @media (max-width: $screen-sm) {
        
        padding: 4em 0em;
      }
}

.section7{
    height: 100%;
    min-height: 100%;
    padding: 10em 0em;

    @media (max-width: $screen-sm) {
        
        padding: 4em 0em;
      }
}

.section8{
    height: 100%;
    min-height: 100%;
    padding: 10em 0em;

    @media (max-width: $screen-sm) {
        
        padding: 4em 0em;
      }
}

.section9{
    height: 100%;
    min-height: 100%;
    padding-top: 5em;

    @media (max-width: $screen-sm) {
        
        padding-top: 4em;
      }
}

canvas{
    box-sizing: border-box;
    height: 100vh;
    width: 100vw;
    pointer-events: none;
    padding: 0px;
    border: 0px;
    margin: 0px;
    width: 100%;
    border-image-width: 0px;
    display: block;
    position: fixed;
    z-index: -1;
}

.background{
    width: 100%;
    height: 100vh;
    position: fixed;
    left: 0;
    right: 0;
    font-family: var(--font-family);
    color: var(--dark-color);
    background: var(--bg-gradient);
    z-index: -2;
    background: transparent;

}


#leaf-container {
    position:absolute;
    left:0;
    top: 0;
    overflow: hidden;
    width: 100%;
    z-index: -1;

    @media (max-width: $screen-md) {
        
      }

      @media (max-width: $screen-sm) {

      }
}

.dad{
    overflow: hidden;
}


#leaf-container2 {
    position:absolute;
    right:0;
    left: 0;
    top: 0;
    bottom: 0;
    overflow: hidden;
    height: 100vh;
    width: 100%;
    background: red;
    z-index: -1;
}

.dot{
    width:35px;
    height:35px;
    position:absolute;
    background: url(https://cdn.glitch.com/a4f49ae8-a36d-49df-a274-c38047ae8ac0%2Fexternal-content.duckduckgo.png?v=1625017364168);
    background-size: 100% 100%;
  }


.site {
        overflow-x: hidden;
        /* Unnecessary IMHO, uncomment next line to force hidden behavior */
        /* overflow-x: hidden !important; */
        /* Additional tunning proposed by the community */
        position: relative;
        width: 100%;
    }

  
