.partners{
    

    width: 100%;
    text-align: center;
    

    > :nth-child(1) {
        max-width: 1420px;
        padding-left: 4vw;
        padding-right: 4vw;
        margin-left: auto;
        margin-right: auto;
    }

    .partners-header{
        padding: 1em;
    
        h1 {
          color: $brand-secondary;
          font-family: poppins-bold;
          font-size: 50px;
          margin: 0;
          text-transform: uppercase;
  
          @media (max-width: $screen-phone) {
              font-size: 10vmin;
              
          }
      }
  
      h2 {
          color: $brand-primary;
          font-family: poppins-regular;
          font-size: 23px;
          margin: 0;
          text-transform: uppercase;
          @media (max-width: $screen-phone) {
              font-size: 5vmin;
              
          }
      }
  
      p{
          color: $brand-primary;
          font-family: poppins-regular;
  
          @media (max-width: $screen-phone) {
              font-size: 3.5vmin;
              
          }
      }
    
        hr {
            width: 4em;
            margin-bottom: 2em;
            margin-top: 0.8em;
            margin-left: auto;
            margin-right: auto;
            border: 2px solid $brand-secondary;
            border-radius: 5px;
            background: $brand-secondary;
            
            
        }   
    
       
    }
   
}

.partners-hud-section {
    display: flex;
    justify-content: center;
    padding-top: 2em;
    width: 100%;
  
    > :nth-child(1) {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-gap: 2em;
      width: 1420px;
      padding-left: 4vw;
      padding-right: 4vw;
  
      @media (max-width: $screen-sm) {
        
        display: block;
      }
  
    }

    .hud {
        width: 100%;
        height: 12em;
        background: rgba(255, 255, 255, 0.375);
        box-shadow: 0 0.75rem 2rem 0 rgba(0, 0, 0, 0.1);
        border-radius: 1rem;
        border: 1px solid rgba(255, 255, 255, 0.125);
      
        &:first-child {
          margin-left: 0;
        }
      
        &:last-child {
          margin-right: 0;
        }
      
        @media (max-width: $screen-sm) {
          margin: 0.5em 0em;
          height: 7em;
        }

        @media (max-width: $screen-phone) {
      
          height: 6em;
          
      }
      
      }
  
  }