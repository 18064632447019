@font-face {
    font-family: 'poppins-regular';
    src: url(../../fonts/Poppins-Regular.ttf) format('truetype');
}

@font-face {
    font-family: 'poppins-black';
    src: url(../../fonts/Poppins-Black.ttf) format('truetype');
}

@font-face {
    font-family: 'poppins-bold';
    src: url(../../fonts/Poppins-Bold.ttf) format('truetype');
}

@font-face {
    font-family: 'poppins-regular';
    src: url(../../fonts/Poppins-Regular.ttf) format('truetype');
}

@font-face {
    font-family: 'poppins-light';
    src: url(../../fonts/Poppins-Light.ttf) format('truetype');
}


@font-face {
    font-family: 'poppins-extralight';
    src: url(../../fonts/Poppins-ExtraLight.ttf) format('truetype');
}

