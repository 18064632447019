.about{
    
    display: flex;
    justify-content: center;
    align-items: center;
    

    > :nth-child(1) {
        width: 1420px;
        padding-left: 4vw;
        padding-right: 4vw;
        display: grid;
        grid-template-columns: 1fr 10%;

        @media (max-width: $screen-md) {
            grid-template-columns: 1fr;
        }
        
        
    }
   
}

.about-var{
    padding: 1em;

    h1 {
        color: $brand-secondary;
        font-family: poppins-bold;
        font-size: 50px;
        margin: 0;
        text-transform: uppercase;

        @media (max-width: $screen-phone) {
            font-size: 10vmin;
            
        }
    }

    h2 {
        color: $brand-primary;
        font-family: poppins-regular;
        font-size: 23px;
        margin: 0;
        text-transform: uppercase;
        @media (max-width: $screen-phone) {
            font-size: 5vmin;
            
        }
    }

    p{
        color: $brand-primary;
        font-family: poppins-regular;

        @media (max-width: $screen-phone) {
            font-size: 14px;
            
        }
    }

    hr {
        width: 4em;
        margin-bottom: 2em;
        margin-top: 0.8em;
        border: 2px solid $brand-secondary;
        border-radius: 5px;
        background: $brand-secondary;
        
        
    }
}

.about-img{
    padding: 1em;
    display: flex;
    justify-content: flex-end;

    @media (max-width: $screen-md) {
        justify-content: center;
    }

    img{
        width: 80%;
        @media (max-width: $screen-lg) {
            width: 40vmin;
            height: 40vmin;
        }

        @media (max-width: $screen-md) {
            width: 50vmin;
            height: 50vmin;
        }
    }
}

.future-button{
    border: 2px solid $brand-primary;

  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.7em 2em;
  width: 12em;
  height: 2.5em;
  background: rgba(255, 255, 255, 0.375);
    box-shadow: $box-shadow;
    border-radius: 1rem;
    border: 1px solid rgba(255, 255, 255, 0.125);

    p{
        @media (max-width: $screen-phone) {
            font-size: 14px;
        }
    }
}