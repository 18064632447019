/* Color */

$black: #000;
$white: #FFF;

$brand-primary: #1A1627;
$brand-secondary: #87F6AD;
$box-shadow: 0 0.75rem 2rem 0 rgba(35,35,35,.1);

$body-bg:    white;
$color-text: $black;

$spacer: 1rem;

/* Typography */

$font-family-base: 'Source Sans Pro', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;

$font-weight-lighter:     lighter;
$font-weight-thin:        100;
$font-weight-extra-light: 200;
$font-weight-light:       300;
$font-weight-normal:      400;
$font-weight-medium:      500;
$font-weight-semi-bold:   600;
$font-weight-bold:        700;
$font-weight-extra-bold:  800;
$font-weight-black:       900;
$font-weight-bolder:      bolder;

$font-size-base:    1rem;
$font-size-large:   $font-size-base * 1.5;
$font-size-medium:  $font-size-base * 1.25;
$font-size-small:   $font-size-base * 0.85;
$font-size-smaller: $font-size-base * 0.8;
$font-size-tiny:    $font-size-base * 0.75;

$line-height-base:    1.3;
$line-height-small:   1.4;
$line-height-smaller: 1.4;
$line-height-tiny:    1.5;

/* Grid / Screen Sizes */
$enable-grid-classes: true;

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 1024px,
  xl: 1200px,
  xxl: 1480px,
  xxxl: 1800px
);

// Extra small screen / phone
//** Deprecated `$screen-xs` as of v3.0.1
$screen-xs:                  480px !default;
//** Deprecated `$screen-xs-min` as of v3.2.0
$screen-xs-min:              $screen-xs !default;
//** Deprecated `$screen-phone` as of v3.0.1
$screen-phone:               $screen-xs-min !default;

// Small screen / tablet
//** Deprecated `$screen-sm` as of v3.0.1
$screen-sm:                  768px !default;
$screen-sm-min:              $screen-sm !default;
//** Deprecated `$screen-tablet` as of v3.0.1
$screen-tablet:              $screen-sm-min !default;

// Medium screen / desktop
//** Deprecated `$screen-md` as of v3.0.1
$screen-md:                  992px !default;
$screen-md-min:              $screen-md !default;
//** Deprecated `$screen-desktop` as of v3.0.1
$screen-desktop:             $screen-md-min !default;

// Large screen / wide desktop
//** Deprecated `$screen-lg` as of v3.0.1
$screen-lg:                  1200px !default;
$screen-lg-min:              $screen-lg !default;
//** Deprecated `$screen-lg-desktop` as of v3.0.1
$screen-lg-desktop:          $screen-lg-min !default;

// So media queries don't overlap when required, provide a maximum
$screen-xs-max:              ($screen-sm-min - 1) !default;
$screen-sm-max:              ($screen-md-min - 1) !default;
$screen-md-max:              ($screen-lg-min - 1) !default;

$grid-gutter-width: 32px;
$grid-columns: 16;
$grid-max-width: none;

$layout-vertical-padding-mobile: 100px;
$layout-vertical-padding: 150px;

$headings-margin-bottom: $spacer;
$paragraph-margin-bottom: $spacer;
$label-margin-bottom: $spacer * 0.5;

// Z Index Stack
$zindex-ajax-cart: 50;
$zindex-header: 100;
$zindex-skip-to-content: 10000;

