

.footer{
    
    position: relative;
    bottom: 0;
    width: 100%;
   
    height: 100%;
    
   


    > :nth-child(1) {
        max-width: 1420px;
        padding: 4em 4vw;
        margin-left: auto;
        margin-right: auto;
        
    }  
}

.footer-var{
    padding: 1em;

    strong {
        color: $brand-primary;
        font-family: poppins-bold;
        margin: 0;
        text-transform: uppercase;
        
    }

    h2 {
        color: $brand-primary;
        font-family: poppins-regular;
        font-size: 23px;
        margin: 0;
        text-transform: uppercase;
        @media (max-width: $screen-phone) {
            font-size: 5vmin;
            
        }
    }
    
    
    p{
        color: $brand-primary;
        font-family: poppins-regular;

        @media (max-width: $screen-phone) {
            font-size: 3.5vmin;
            
        }
    }

    hr {
        width: 4em;
        margin-bottom: 2em;
        margin-top: 0.8em;
        border: 2px solid $brand-secondary;
        border-radius: 5px;
        background: $brand-secondary;
    }

    

    @media (max-width: $screen-sm) {

        > :nth-child(3) {
            padding-top: 4em;
        
        }

        > :nth-child(4) {
            padding-top: 4em;
        
        }

    }
}

.disclaimer{
    span{
        font-family: poppins-bold;
        color: $brand-primary;
    }

    p{
        color: $brand-primary;
        font-family: poppins-regular;

        @media (max-width: $screen-phone) {
            font-size: 3.5vmin;
            
        }
    }
}