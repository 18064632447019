
.faq{
    

    width: 100%;
    text-align: center;
    

    > :nth-child(1) {
        max-width: 1420px;
        padding-left: 4vw;
        padding-right: 4vw;
        margin-left: auto;
        margin-right: auto;
    }

    .faq-header{
        padding: 1em;
    
        h1 {
            color: $brand-secondary;
            font-family: poppins-bold;
            font-size: 50px;
            margin: 0;
            text-transform: uppercase;
    
            @media (max-width: $screen-phone) {
                font-size: 10vmin;
                
            }
        }
    
        h2 {
            color: $brand-primary;
            font-family: poppins-regular;
            font-size: 23px;
            margin: 0;
            text-transform: uppercase;
            @media (max-width: $screen-phone) {
                font-size: 5vmin;
                
            }
        }
    
        p{
            color: $brand-primary;
            font-family: poppins-regular;
    
            @media (max-width: $screen-phone) {
                font-size: 3.5vmin;
                
            }
        }
        
    
        hr {
            width: 4em;
            margin-bottom: 2em;
            margin-top: 0.8em;
            margin-left: auto;
            margin-right: auto;
            border: 2px solid $brand-secondary;
            border-radius: 5px;
            background: $brand-secondary;
            
            
        }   
    
    }
   
}


.faq-grid{
    display: grid;
    grid-template-columns: 1fr 1fr;

    @media (max-width: $screen-md) {
        display: block;
    }
}

.faq-img{
    padding: 1em;
    display: flex;
    justify-content: center;
    align-items: center;

    

    img{
        width: 40vmin;
        height: 40vmin;
        

        @media (max-width: $screen-md) {
            display: none;
        }
    }
}


.faq-row-wrapper {
    .faq-title {
        color: $brand-primary;
    }

    .row-title-text{
        color: $brand-primary;
        padding-left: 1em;
        @media (max-width: $screen-lg) {
            font-size: 14px;
          }

          @media (max-width: $screen-md) {
            font-size: 18px;
          }

          @media (max-width: $screen-sm) {
            font-size: 14px;
          }

          @media (max-width: $screen-phone) {
            font-size: 4vmin
          }
    } 

    .faq-body {
        .faq-row {
            margin-top: 0.5em;
            background: rgba(255, 255, 255, 0.375);
            box-shadow: $box-shadow;
            border-radius: 2rem;
            border: 1px solid rgba(255, 255, 255, 0.125);
            .row-title {
                color: $brand-primary;
                
            }

            .row-content {
                p{
                    color: $brand-primary;
                    text-align: left;
                    margin-left: 1em;
                    padding-bottom: 1em;
                    @media (max-width: $screen-lg) {
                        font-size: 14px;
                      }

                      @media (max-width: $screen-md) {
                        font-size: 18px;
                      }
            
                      @media (max-width: $screen-sm) {
                        font-size: 14px;
                      }

                      @media (max-width: $screen-phone) {
                        font-size: 3.5vmin
                      }
                }
                a{
                    text-decoration: underline;
                }

                li{
                    color: $brand-primary;
                   
                    text-align: left;
                    margin-left: 1em;

                    @media (max-width: $screen-lg) {
                        font-size: 14px;
                      }

                      @media (max-width: $screen-md) {
                        font-size: 18px;
                      }
            
                      @media (max-width: $screen-sm) {
                        font-size: 14px;
                      }

                      @media (max-width: $screen-phone) {
                        font-size: 3.5vmin
                      }
                }
            }
        }
    }
}



