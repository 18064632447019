// -------------------------
// Helper Classes
// -------------------------

.visually-hidden {
  @include visually-hidden();
}

.js-focus-hidden:focus {
  outline: none;
}

.label-hidden {
  @include visually-hidden();

  // No placeholders, so force show labels
  .no-placeholder & {
    @include visually-shown();
  }
}

.visually-shown {
  @include visually-shown();
}

// Only show when JS is not supported
.no-js:not(html) {
  display: none;

  .no-js & {
    display: block;
  }
}

// Only show when JS is supported
.js {
  .no-js & {
    display: none;
  }
}


/*============================================================================
  Skip to content button
    - Overrides .visually-hidden when focused
==============================================================================*/
.skip-link:focus {
  @include visually-shown(absolute);
  color: $color-text;
  background-color: $body-bg;
  padding: $grid-gutter-width / 2;
  z-index: $zindex-skip-to-content;
  transition: none;
}
