.hud-section {
  display: flex;
  justify-content: center;
  padding-top: 2em;
  width: 100%;

  > :nth-child(1) {
    display: flex;
    justify-content: center;
    width: 1420px;
    padding-left: 4vw;
    padding-right: 4vw;

    @media (max-width: $screen-sm) {
      display: block;
    }

  }

  .hud {
    margin: 0em 0.5em;
    width: 100%;
    height: 6em;
    background: rgba(255, 255, 255, 0.375);
    box-shadow: $box-shadow;
    border-radius: 1rem;
    border: 1px solid rgba(255, 255, 255, 0.125);
    display: flex;
    align-items: center;
    padding: 1em;

    @media (max-width: $screen-sm) {
      display: grid;
      place-items: center;
    }
    
    &:first-child {
      margin-left: 0;
    }
  
    &:last-child {
      margin-right: 0;
    }

    p{
      color: $brand-primary;
      margin-left: 1em;
      font-family: poppins-regular;
      text-transform: uppercase;

      @media (max-width: $screen-md) {
        font-size: 14px;
      }

      @media (max-width: $screen-sm) {
        font-size: 14px;
        margin-left: 0em;
      }

      @media (max-width: $screen-phone) {
        font-size: 14px
      }
    }

    .cup{
      width: 5vmin;
      @media (max-width: $screen-sm) {
        width: 2.5em;
      }

      @media (max-width: $screen-phone) {
      
        width: 2.5em;
        
    }
      
    }
  
    @media (max-width: $screen-sm) {
      margin: 0.5em 0em;
      height: 6em;
    }

    @media (max-width: $screen-phone) {
      
      height: 6em;
      
  }
  
  }

}
