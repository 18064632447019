// Account Form stuff
.form-container {
  display: block;
  margin: 0 auto;

  @media (min-width: 576px) {
    max-width: 400px;    
  }

  .errors {
    text-align: center;
    margin-bottom: 2rem;

    ul {
      list-style: none;
      margin: 0;
      padding: 0;
    }
  }

  p {
    margin-bottom: 1rem;
    text-align: center;
  }
}

.form-group {
  margin-bottom: 1rem;

  label {
    font-size: 10px;
    text-transform: uppercase;
    display: inline-block;
    margin-bottom: 4px;
  }
}

.form-control {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;  
  display: block;
  width: 100%;
  box-sizing: border-box;
  border: 1px solid #000;
  padding: 5px 6px;
  border-radius: 0px !important;

  @media screen and (-webkit-min-device-pixel-ratio:0) and (max-device-width: 768px) {
    font-size: 16px;
  }

  &:focus {
    outline: none
  }  
}

select.form-control {
  background-position: right center;
  padding-right: 34px;
  padding-left: 10px;
  text-indent: 0.01px;
  text-overflow: '';
  cursor: pointer;
}