.community{
    
    display: flex;
    justify-content: center;
    align-items: center;
    

    > :nth-child(1) {
        width: 1420px;
        padding-left: 4vw;
        padding-right: 4vw;
        display: grid;
        grid-template-columns: 1fr;

        @media (max-width: $screen-md) {
            grid-template-columns: 1fr;
        }
        
        
    }
   
}

.community-header{
    padding: 1em;
    text-align: center;

    h1 {
        color: $brand-secondary;
        font-family: poppins-bold;
        font-size: 50px;
        margin: 0;
        text-transform: uppercase;

        @media (max-width: $screen-phone) {
            font-size: 10vmin;
            
        }
    }

    h2 {
        color: $brand-primary;
        font-family: poppins-regular;
        font-size: 23px;
        margin: 0;
        text-transform: uppercase;
        @media (max-width: $screen-phone) {
            font-size: 5vmin;
            
        }
    }

    p{
        color: $brand-primary;
        font-family: poppins-regular;

        @media (max-width: $screen-phone) {
            font-size: 14px;
            
        }
    }

    hr {
        width: 4em;
        margin-bottom: 2em;
        margin-top: 0.8em;
        border: 2px solid $brand-secondary;
        border-radius: 5px;
        background: $brand-secondary;
        margin-left: auto;
        margin-right: auto;
        
        
    }
}


.community-var{
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding-top: 1em;

    h1 {
        color: $brand-secondary;
        font-family: poppins-bold;
        font-size: 50px;
        margin: 0;
        text-transform: uppercase;

        @media (max-width: $screen-phone) {
            font-size: 10vmin;
            
        }
    }

    h2 {
        color: $brand-primary;
        font-family: poppins-regular;
        font-size: 23px;
        margin: 0;
        text-transform: uppercase;
        @media (max-width: $screen-phone) {
            font-size: 5vmin;
            
        }
    }

    p{
        color: $brand-primary;
        font-family: poppins-regular;

        @media (max-width: $screen-phone) {
            font-size: 3.5vmin;
            
        }

        
    }

    small{
        color: $brand-primary;
        font-family: poppins-regular;
        padding-bottom: 0.5em;

        @media (max-width: $screen-sm) {
            font-size: 12px;
            
        }

        @media (max-width: $screen-phone) {
            font-size: 12px;
            
        }
    }
    

    > :nth-child(1) {
        width: 1420px;
        padding-left: 4vw;
        padding-right: 4vw;
        display: grid;
        grid-template-columns: repeat(4, 1fr);

        @media (max-width: $screen-sm) {
            grid-template-columns: repeat(2, 1fr);
            
        }

    }

    .community-button{
        border: 2px solid $brand-primary;
    
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0.7em 2em;
      
      
      background: rgba(255, 255, 255, 0.375);
        box-shadow: $box-shadow;
        border-radius: 1rem;
        border: 1px solid rgba(255, 255, 255, 0.125);
        margin-left: 0;
        margin-right: 0;

        @media (max-width: $screen-md) {
            padding: 0.4em 2em;
            font-size: 14px;
            
        }
    }

    .social-icon{
        color: $brand-secondary;
        font-size: 2.5em;

        @media (max-width: $screen-md) {
            font-size: 2em;
            
        }
    }

    .twitter-hud{
        border-right: 1px solid $brand-secondary;
        display: grid;
        place-items: center;
    }
    .facebook-hud{
        border-right: 1px solid $brand-secondary;
        display: grid;
        place-items: center;

        @media (max-width: $screen-sm) {
            border: none;
            
        }
    }
    .instagram-hud{
        border-right: 1px solid $brand-secondary;
        display: grid;
        place-items: center;

        @media (max-width: $screen-sm) {
            margin-top: 1em;
            
        }
    }
    .telegram-hud{
        display: grid;
        place-items: center;
        @media (max-width: $screen-sm) {
            margin-top: 1em;
            
        }
    }
}


