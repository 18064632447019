.main {
    display: flex;
    justify-content: center;
    text-align: center;
    padding-top: 8em;

    > :nth-child(1) {
        max-width: 30em;
        padding: 4em 1em 4em 1em;
    }

    > :nth-child(2) {
        max-width: 1420px;
        padding: 4em 1em 4em 1em;
    }

    h1 {
        color: $brand-primary;
        font-family: poppins-bold;
        font-size: 36px;
        margin-top: 0.5em;
        text-transform: uppercase;

        @media (max-width: $screen-phone) {
            font-size: 7vmin;
          }
    }

    hr {
        width: 6em;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 1em;
        padding-top: 0.1em;
        background-color: $brand-primary;
        border-color: $brand-primary;
    }

    span {
        color: $brand-secondary;
            font-family: poppins-bold;
    }

    

}

.secondary-logo {
    width: 12em;
    @media (max-width: $screen-phone) {
        width: 40vmin;
        
    }
    

}

.viewchart-container {

    padding: 0.7em 2em;
    width: 11em;
    margin-left: auto;
    margin-right: auto;
    background: rgba(255, 255, 255, 0.375);
    box-shadow: $box-shadow;
    border-radius: 2rem;
    border: 1px solid rgba(255, 255, 255, 0.125);

    p {
        color: $brand-primary;
        font-family: poppins-bold;
        text-transform: uppercase;
        font-size: 14px;
    }
}